import { Component, OnInit, Input } from '@angular/core';
import { ModulemappingService } from 'src/app/erp/services/modulemapping/modulemapping.service';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login/login.service';
import { StockReportsService } from 'src/app/erp/services/reports/stockreports/stockreports.service';
import { saveAs } from 'file-saver';
import Swal from 'sweetalert2';
import * as  localForage from 'localforage';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public moduleList: any[] = [];
  user:any;
  itemList: any[];
  itemsData: any[];
  constructor(private moduleMappingService: ModulemappingService, private router: Router,private loginService: LoginService, private stockReportService: StockReportsService) { }

  ngOnInit() {
    this.getModules();
    this.getItemsData();

  }

  async getItemsData() {
    this.user = this.loginService.getUser();
    let inputObj = {
      LocationID: this.user.LocationID,
      CategoryId: 0,
    };
    let itemdata;
    try {
      let itemdata = await localForage.getItem('itemsData');
    } catch (err) {
      console.log(err);
    }
    if (itemdata == null || itemdata == undefined) {
      this.stockReportService.getClosingStock(inputObj).subscribe(result => {
        if (result.body.length > 0) {
          this.itemList = [];
          let resData = {};
          result.body.forEach(item => {
            resData = {
              ID: item.ItemID,
              Code: item.DisplayText
            };
            this.itemList.push(resData);
          });
          this.itemsData = this.itemList;
        }
        localForage.setItem('itemsData', this.itemsData);
      });
    }
  }

  saveItemsData(){
    const blob = new Blob([JSON.stringify(this.itemsData)], {type : 'application/json'});
    saveAs(blob,'items-list.json');
  }
  
  getModules() {
    this.moduleMappingService.getModules().subscribe(res => {
      this.moduleList = [];
      if (res.body.length > 0) {
        res.body.map(menu => {
          if (menu.ModuleID === 1) { menu.path = '/erp/dashboard/' + menu.ModuleID; }
          if (menu.ModuleID === 2) { menu.path = '/erp/dashboard/' + menu.ModuleID; }
          if (menu.ModuleID === 3) { menu.path = '/accounts/dashboard/' + menu.ModuleID; }
          if (menu.ModuleID === 4) { menu.path = '/hrms/dashboard/' + menu.ModuleID; }
          if (menu.ModuleID === 5) { menu.path = '/analitics/dashboard/' + menu.ModuleID; }
          if (menu.ModuleID === 7) { menu.path = '/service/dashboard/' + menu.ModuleID; }
        });
      }
      this.moduleList = res.body;
    });
  }

  updateModuleId(moduleId: any) {
    localStorage.setItem('moduleId', moduleId);
  }
}
